import React from "react";
import RequestButton from "../RequestButton";

const ImmunizationsInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Immunizations Q & A
        </p>

        <p className="text-xl text-gray-800 mb-2">What are immunizations?</p>
        <p className="text-md text-gray-600 mb-2">
          Immunizations are a defense against certain diseases. When a virus or
          other organism enters your body, your immune system detects the threat
          and goes into action. Your white blood cells develop antibodies that
          seek out and destroy the invading organisms, so your body can recover
          from the infection.
        </p>
        <p className="text-md text-gray-600 mb-12">
          After creating the antibodies you need for a particular infection,
          your immune system stores the blueprint for future use. If you should
          come into contact with the same organism again your body recognizes it
          and destroys it quickly. This is how you build up an immunity to
          disease.
        </p>

        <p className="text-xl text-gray-800 mb-2">How do immunizations work?</p>
        <p className="text-md text-gray-600 mb-2">
          Immunizations use your immune system’s abilities to create antibodies
          to fight diseases you haven’t had. They use safe forms of the
          organisms to get your immune system to make the antibodies you need.
        </p>
        <p className="text-md text-gray-600 mb-12">
          The vaccines used to immunize you are either dead samples of the
          organism or safe, weakened versions. Some vaccines contain an inactive
          sample of a toxin the organism makes. In each case, your body responds
          to the vaccine by creating antibodies that will recognize these
          organisms and kill them should you ever encounter a live version.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What immunizations do I need?
        </p>
        <p className="text-md text-gray-600 mb-2">
          You should get most of the immunizations you need as a child,
          including vaccination against:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Measles</li>
          <li>Mumps</li>
          <li>Rubella</li>
          <li>Diphtheria</li>
          <li>Tetanus</li>
          <li>Chickenpox</li>
          <li>Rotavirus</li>
          <li>Pneumococcal infection</li>
          <li>Whooping cough (pertussis)</li>
          <li>Haemophilus influenzae type b (Hib)</li>
          <li>Polio</li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          Some of these vaccines last you for the rest of your life. Others need
          boosters to maintain your immunity. You may have missed out on having
          some of your childhood vaccinations, or the vaccines might not have
          been available to you when you were a child.
        </p>
        <p className="text-md text-gray-600 mb-2">
          With age, immunity can decline, making you more vulnerable again. You
          might also have a role in life that increases your risk of getting
          sick, like working in health care. The Sunstate Medical Associates
          team can identify which immunizations you might need and prepare an
          immunization schedule for you as an adult.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Hepatitis B immunizations might be advisable if you work in a
          high-risk environment or your partner has the disease. If you haven’t
          had the HPV (human papillomavirus) vaccine, you can now have it up to
          the age of 45. HPV is the primary cause of cervical cancer.
        </p>
        <p className="text-md text-gray-600 mb-2">
          If you haven’t got immunity to chickenpox, the varicella vaccine can
          prevent you from getting shingles, a painful and distressing condition
          caused by the chickenpox virus. Other immunizations like measles,
          mumps, and rubella might benefit you depending on your age and other
          risk factors. An annual flu shot can also protect you against the type
          of flu that’s likely to be around that year.
        </p>
        <p className="text-md text-gray-600 mb-10">
          The Sunstate Medical Associates team can advise you on which
          immunizations you need for optimal protection. Call them today to
          arrange an immunization review or book an appointment online.
        </p>

        <RequestButton />
      </div>
    </div>
  );
};

export default ImmunizationsInfo;
