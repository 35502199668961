import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import ImmunizationsInfo from "../../components/services/ImmunizationsInfo";

const Immunizations = () => {
  return (
    <Layout>
      <PageHeader text="Immunizations" />
      <ServicesHeader>
        Immunizations prevent serious diseases and save millions of lives every
        year. Find out if there are any immunizations you’ve missed or new ones
        you need by teaming with the expert providers at Sunstate Medical
        Associates in Lake Mary, Florida. The board-certified physicians will
        evaluate your immunization history and give you any boosters or new
        vaccines you need to stay safe. Call Sunstate Medical Associates today
        to arrange an appointment or use the convenient online booking form.
      </ServicesHeader>
      <ImmunizationsInfo />
      <Conditions />
    </Layout>
  );
};

export default Immunizations;
